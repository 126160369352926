<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <!--
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Compañía (*):</label>
                <input type="text" class="form-control col-md-6" v-model="item.cmp_id" disabled="true">
              </div>
            </div>
          </div>
        -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Código (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.men_cdgo" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Descripción (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.men_descripcion" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Orden (*):</label>
                <input type="text" class="form-control col-md-8" v-model="item.men_orden" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Sólo para aplicación:</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.men_app">
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: ''
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Crear Menú';
        this.buttontext = 'Agregar Menú';
      } else {
        this.title = 'Editar Menú';
        this.buttontext = 'Actualizar Menú';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {
      setClose(close){
        this.close = close;
      },
      saveItem(){
        if (this.add){
          let uri = '/menus/add';
          //alert(this.item);
          this.axios.post(uri, this.item)
          .then(response => {
            this.$router.replace({ name: 'DisplayMenu' });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/menus/update/' + this.item._id;
          this.axios.post(uri, this.item).then((response) => {
            if (this.close){
              this.$router.push({name: 'DisplayMenu'});
            }
            this.message = 'Registro guardado';
          });
        }
      },
      getItem(id)
      {
        let uri = '/menus/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
        });
      },
      cancel(){
        this.$router.replace({name: 'DisplayMenu'});
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
